window.Client = {
    init: function() {
        
    },

    onRender: function(screenId) {
        let phoneScreenId = "id-e462a04c";

        if (screenId === phoneScreenId) {
          var phoneNumberPattern =
            `^[\+][0-9]{10,16}$`;
        
          document.querySelector(
            `#${phoneScreenId} input[data-variable="phone"]`
          ).pattern = phoneNumberPattern;
        }
    }
}